.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone_img {
  display: block;
  width: 100px;
  padding: 10px;
  height: 100%;
}

.dropzone_thumb {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.dropzone_thum_img {
  display: inline-flex;
  border-radius: 2;
  border: 1px solid #eaeaea;
  margin: 5px;
  width: 100;
  height: 100;
  padding: 4;
  box-sizing: border-box;
}

.thumbcontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.react-dropdown-select-dropdown {
  width: 100% !important;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin-bottom: 0px !important;
}

.error {
  color: red;
}

.list {
  user-select: none;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 16px;
}

.item {
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: Black;
  padding-top: 30px;
  height: 150px;
  width: 200px;
  cursor: grab;
  font-size: 20px;
  user-select: none;
  border: 1px solid #adadad;
}

.item img {
  width: auto;
  height: 70px;
  cursor: grab;
}

.dragged {
  background-color: rgb(37, 37, 197);
}

@media (min-width: 600px) {
  .list {
    grid-gap: 24px;
  }
}

.card-border {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 88%) !important;
}

.antformrange {
  display: flex !important;
}

.close_img {
  line-height: 12px;
  width: 18px;
  font-size: 15px;
  font-family: tahoma;
  margin-top: 1px;
  margin-right: 2px;
  position: absolute;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}